(function($) {
    $(document).ready(function() {

        // Masterhead
        $('.masterhead .search').on('click', 'button', function() {
            $(this).parents('body').toggleClass('freeze');
            $(this).parents('.masterhead').find('.menu span').fadeToggle();
            $(this).children().toggleClass('icon-close icon-search');
            $(this).parents('.masterhead').siblings('.searchBar').toggleClass('on')           
        });
        $('.masterhead .menu').on('click', 'span', function() {
            $(this).parents('body').toggleClass('freeze');
            $(this).parents('.masterhead').find('.search button').fadeToggle();
            $(this).toggleClass('icon-close icon-dehaze');
            $(this).parents('.masterhead').siblings('.submenu').toggleClass('on')
        });
        

        // Owl Carousel (HP)
        if ($('#owl-slideHP').length) {
            $('#owl-slideHP').owlCarousel({
                items: 3,
                stagePadding: 100,
                loop: true,
                margin: 10
            })
        };


        // crosslink
        $('a[rel=emhsli]').addClass('slidelink');
        $('a[rel=emhsli]').attr('title', 'Educational Slideshow');
        $('a[rel=emhsli]').attr('target', '_blank');

        $('a[rel=quiz-em]').addClass('quizlink');
        $('a[rel=quiz-em]').attr('title', 'Learning Quiz');
        $('a[rel=quiz-em]').attr('target', '_blank');

        $('a[rel=emhimg]').addClass('imglink');
        $('a[rel=emhimg]').attr('title', 'Medical Image');
        $('a[rel=emhimg]').attr('target', '_blank');

        $('a[rel=dict]').addClass('dictlink');
        $('a[rel=dict]').attr('title', 'Definition');
        $('a[rel=dict]').attr('target', '_blank');


        // Back to Top
        $(window).scroll(function() {
            if ($(window).scrollTop() > 1000) {
                $('#backTop').fadeIn();
                $('#backTop').on('click', function() {
                    $('body, html').scrollTop(0)
                })
            } else {
                $('#backTop').fadeOut()
            }
        });


    });
})(jQuery);


// function to toggle visibility
function toggle(targetId, signId) {
    if (document.getElementById) {
        target = document.getElementById(targetId);
        if (signId != '') { sign = document.getElementById(signId); }
        if (target.style.display == "none") {
            target.style.display = "";
            if (sign) { sign.src = sign.src.replace('plusSign', 'minusSign'); }
        } else {
            target.style.display = "none";
            if (sign) { sign.src = sign.src.replace('minusSign', 'plusSign'); }
        }
    }
}