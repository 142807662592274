(function($) {
    $(document).ready(function() {

        // granny TOC Menu
        if ($('.toc').length && $('#imageSlider').length === 0) {            

            var stickyTOC = new Waypoint.Sticky({
                element: $('.story .toc')[0],
                offset: -$('.toc').outerHeight()
            });
            $('main').waypoint(function(direction) {
                if (direction === 'down') {
                    $('header').children('.TOCBar').animate({ top: "0" },200),                                 
                    $('header').addClass('TOC')
                } else {
                    $('header').children('.TOCBar').animate({ top: "-102px" },200),
                    $('header').children('.submenu').css("display", ""),
                    $('main').children('.story').find('.toc ul').css("display", ""), 
                    $('header').removeClass('TOC')
                }
            }, {
                offset: -$('.toc').outerHeight() - 500
            });

            $('main').children('.story').find('.toc li a').clone().appendTo('.TOCBar .wrapper-title');
            var waypoints = $('.apPage .wrapper').waypoint(function(direction) {
                if (direction === 'down') {
                    $('header').children('.TOCBar').find('.wrapper-title a').removeClass('active'),
                    $('header').children('.TOCBar').find('.wrapper-title a[href="#' + this.element.querySelector('.bookmark').getAttribute('name') + '"]').addClass('active')
                } else {
                    $('header').children('.TOCBar').find('.wrapper-title a').removeClass('active'),
                    $('header').children('.TOCBar').find('.wrapper-title a[href="#' + this.element.querySelector('.bookmark').getAttribute('name') + '"]').prev().addClass('active')
                }
            }, {
                offset: '100%'
            });

            $(window).scroll(function() {
                var windowBottom = $(this).scrollTop() + $(this).height(),
                    elementTop = $('main .story').offset().top + $('.toc').outerHeight(),
                    percentage = (windowBottom - elementTop) / ($('main .story').height() - $('main .story .article-extra').height()) * 100;
                if (percentage >= 100) {
                    $(".TOCBar .progressbar .indicator ").css("width", "100%"),
                    $('main').children('.social-section').fadeOut()
                } else if (windowBottom >= elementTop) {
                    $(".TOCBar .progressbar .indicator ").css("width", (percentage + '%')),
                    $('main').children('.social-section').fadeIn()
                } else {
                    $(".TOCBar .progressbar .indicator ").css("width", "0%")
                }
            });

            $('.TOCBar > span').on('click', function() {
                $(this).parents('body').toggleClass('noscroll'),
                $(this).toggleClass('icon-close icon-dehaze'),
                $(this).parents('header.TOC').children('.submenu').toggleClass('on'),
                $(this).parents('header').siblings('main').children('.story').find('.toc.stuck ul').toggleClass('on')
            });

            $('.toc ul > li').on('click', 'a', function() {
                $(this).parents('body').removeClass('noscroll'),
                $(this).parents('body').children('header').find('.TOCBar > span').removeClass('icon-close').addClass('icon-dehaze'),
                $(this).parents('body').children('header.TOC').find('.submenu').removeClass('on'),
                $(this).parents('.toc.stuck ul').removeClass('on')
            });

        };


        // lite TOC Menu
        if ($('.toc_lite').length) {  

            $('.apPage .toc_lite').on('mouseenter', '.owl-carousel>li>a', function() {
                $(this).parents('.toc_lite').find('.owl-carousel>li>a').removeClass('open'),
                $(this).parents('.toc_lite').find('.owl-carousel>li>.sub').fadeOut(0),
                $(this).addClass('open'),
                $(this).siblings('.sub').fadeIn(0)
            });

            $('.apPage .toc_lite').on('mouseleave', function() {
                $(this).find('.owl-carousel>li>a').removeClass('open'),
                $(this).find('.owl-carousel>li>.sub').fadeOut(0)
            });

            // mini menu          
            var stickyTOC = new Waypoint.Sticky({
                element: $('.story .toc_lite')[0],
                offset: -$('.toc_lite').outerHeight()
            });
            $('main').waypoint(function(direction) {
                if (direction === 'down') {
                    $('header').children('.TOCBar').animate({ top: "0" },200),                                 
                    $('header').addClass('TOC')
                } else {
                    $('header').children('.TOCBar').animate({ top: "-102px" },200),
                    $('header').children('.submenu').css("display", ""),
                    $('main').children('.story').find('.toc_lite ul').css("display", ""), 
                    $('header').removeClass('TOC')
                }
            }, {
                offset: -$('.toc_lite').outerHeight() - 500
            });

            $(window).scroll(function() {
                var windowBottom = $(this).scrollTop() + $(this).height(),
                    elementTop = $('main .story').offset().top + $('.toc_lite').outerHeight(),
                    percentage = (windowBottom - elementTop) / ($('main .story').height() - $('main .story .article-extra').height()) * 100;
                if (percentage >= 100) {
                    $(".TOCBar .progressbar .indicator ").css("width", "100%"),
                    $('main').children('.social-section').fadeOut()
                } else if (windowBottom >= elementTop) {
                    $(".TOCBar .progressbar .indicator ").css("width", (percentage + '%')),
                    $('main').children('.social-section').fadeIn()
                } else {
                    $(".TOCBar .progressbar .indicator ").css("width", "0%")
                }
            });

            $('.TOCBar > span').on('click', function() {
                $(this).parents('body').toggleClass('noscroll'),
                $(this).toggleClass('icon-close icon-dehaze'),
                $(this).parents('header.TOC').children('.submenu').toggleClass('on'),
                $(this).parents('header').siblings('main').children('.story').find('.toc_lite.stuck > ul').toggleClass('on')
            });

            $('.toc_lite ul > li').on('click', 'a', function() {
                $(this).parents('body').removeClass('noscroll'),
                $(this).parents('body').children('header').find('.TOCBar > span').removeClass('icon-close').addClass('icon-dehaze'),
                $(this).parents('body').children('header.TOC').find('.submenu').removeClass('on'),
                $(this).parents('.toc_lite.stuck > ul').removeClass('on')
            });

            // carousel TOC in mini menu
            $('main').children('.story').find('.toc_lite').clone().appendTo('.TOCBar .wrapper-title');

            $('.TOCBar .wrapper-title>a, .TOCBar .toc_lite .sub').remove(); 

            $('.TOCBar .toc_lite .owl-carousel').owlCarousel({
                nav:true,
                navText: ["<span class='icon-arrow-right2'></span>", "<span class='icon-arrow-right2'></span>"],
                dots: false,
                margin:5,
                slideBy: 10,
                responsive:{
                    980:{
                        items:3
                    },
                    1200:{
                        items:4
                    },
                    1400:{
                        items:5
                    },
                    1600:{
                        items:6
                    }
                }
            });

            $('.TOCBar .toc_lite .owl-carousel .owl-nav').on('mouseenter', '.owl-next', function() {
                $(this).parents('.owl-carousel').trigger('next.owl.carousel')
            });

            $('.TOCBar .toc_lite .owl-carousel .owl-nav').on('mouseenter', '.owl-prev', function() {
                $(this).parents('.owl-carousel').trigger('prev.owl.carousel')
            });

        };


        // Owl Carousel
        $('.owl-carousel.article').owlCarousel({
            margin: 20,
            loop: true,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 3
                },
                1200: {
                    items: 4
                },
                1400: {
                    items: 5
                }
            }
        });
        $('.owl-carousel.rxArt').owlCarousel({
            items: 1,
            margin: 10,
            loop: true,
            nav: true,
            navText: ["<span class='icon-arrow-right2'></span>", "<span class='icon-arrow-right2'></span>"],
            dots: false,
            lazyLoad: true
        });


        // article
        $('#refs').on('click', function () {
            $(this).next().slideToggle()
        });


        // Quiz Mini Menu
        if ($('#quiz-wrapper').length) {

            $('body').children('.wrapper-hero').find('.hero h1').clone().prependTo('#quiz-wrapper .quiz-status');

            $(window).scroll(function() {
                if ($(window).scrollTop() > 500) {
                    $('#quiz-wrapper').find('.quiz-status').fadeIn(200),
                    $('#quiz-wrapper').find('.social-section').addClass('miniBar')
                } else {
                    $('#quiz-wrapper').find('.quiz-status').fadeOut(300),
                    $('#quiz-wrapper').find('.social-section').removeClass('miniBar')
                }
            });

        };


        // Slideshows 
        if ($('#accordion').length) {
            $('#accordion').on('click', 'h3', function() {
                $(".fold").not($(this).next()).slideUp(300);
                $(this).next().slideToggle(600, function(){
                    $('body, html').animate({
                        scrollTop: $(this).offset().top - 65
                    }, 300)
                })
            })
        };


        // Center
        if ($('#emhCtrAcc').length) {
            $('#emhCtrAcc').on('click', 'h3', function() {
                $(".ctrContent").not($(this).next()).slideUp(300);
                $(this).next().slideToggle(600, function(){
                    $('body, html').animate({
                        scrollTop: $(this).offset().top - 65
                    }, 300)
                })
            })
        };


        // Search
        if ($('#search').length) {
            var $SearchList = $(".searchresults");
            $SearchList.each(function() {
                var $Wrapper = $(this).find("ul");
                if ($Wrapper.height() < 750) {
                    $Wrapper.css("height", "auto");
                    $(this).find(".more,.gradient").css("display", "none")
                } else {
                    $Wrapper.css("height", "750px");
                    $(this).find(".more").children("span").on("click", function() {
                        var $ParWrapper = $(this).parents('.searchresults').find('ul'),
                            $CurHeight = $ParWrapper.height();
                        if ($CurHeight == 750) {
                            $ParWrapper.css('height', 'auto');
                            $ParWrapper.children(".gradient").fadeOut();
                            $(this).addClass('active')
                        } else {
                            $ParWrapper.animate({
                                height: 750
                            }, 200);
                            $ParWrapper.children(".gradient").fadeIn();
                            $(this).removeClass('active');
                            $('body, html').stop().animate({
                                scrollTop: $(this).parents('.searchresults').offset().top 
                            }, 600)
                        };
                        return false
                    })
                }
            })
        };


        // Patient Comments
        if ($('.patientWrapper').length) {
            var $ComList = $(".patientWrapper");
            $ComList.each(function() {
                var $Wrapper = $(this).find("ul");
                if ($Wrapper.height() < 300) {
                    $Wrapper.css("height", "auto");
                    $(this).find(".more,.gradient").css("display", "none")
                } else {
                    $Wrapper.css("height", "300px");
                    $(this).find(".more").children("span").on("click", function() {
                        var $ParWrapper = $(this).parents('.patientWrapper').find('ul'),
                            $CurHeight = $ParWrapper.height();
                        if ($CurHeight == 300) {
                            $ParWrapper.css('height', 'auto');
                            $ParWrapper.children(".gradient").fadeOut();
                            $(this).addClass('active')
                        } else {
                            $ParWrapper.animate({
                                height: 300
                            }, 200);
                            $ParWrapper.children(".gradient").fadeIn();
                            $(this).removeClass('active');
                            $('body, html').stop().animate({
                                scrollTop: $(this).parents('.patientWrapper').offset().top 
                            }, 600)
                        };
                        return false
                    })
                }
            })
        };






    });
})(jQuery);