(function ($) {
    "use strict";
    var methods = {
        init: function (options) {

            var defaults = {
                top: 'auto',
                left: 'auto',
                autoOpen: false,
                overlayOpacity: 0.5,
                overlayColor: '#000',
                overlayClose: true,
                overlayParent: 'body',
                closeOnEscape: true,
                closeButtonClass: '.easy-modal-close',
                transitionIn: '',
                transitionOut: '',
                onOpen: false,
                onClose: false,
                zIndex: function () {
                    return (function (value) {
                        return value === -Infinity ? 0 : value + 1;
                    } (Math.max.apply(Math, $.makeArray($('*').map(function () {
                        return $(this).css('z-index');
                    }).filter(function () {
                        return $.isNumeric(this);
                    }).map(function () {
                        return parseInt(this, 10);
                    })))));
                },
                updateZIndexOnOpen: true,
                hasVariableWidth: false,
                message: '',
                messageDetail: ''
            };

            options = $.extend(defaults, options);

            // append html template to body
            $('<div class="easy-modal-inner">\
                    <div class="easy-modal-header">\
                        <h4 class="easy-modal-title"></h4>\
                        <a type="button" class="easy-modal-close easy-modal-close-icon">X</a>\
                    </div>\
                    <div class="easy-modal-body">\
                        <h4 class="easy-modal-message">' + options.message + '</h4>\
                        <p class="easy-modal-message-detail">' + options.messageDetail + '</p>\
                        <div class="easy-modal-footer">\
                            <button class="easy-modal-close easy-modal-button" title="Close">Close</button>\
                        </div>\
                    </div>\
                </div>').appendTo($(this));

            return this.each(function () {

                var o = options,
                    $overlay = $('<div class="lean-overlay"></div>'),
                    $modal = $(this);

                $overlay.css({
                    'display': 'none',
                    'position': 'fixed',
                    // When updateZIndexOnOpen is set to true, we avoid computing the z-index on initialization,
                    // because the value would be replaced when opening the modal.
                    'z-index': (o.updateZIndexOnOpen ? 0 : o.zIndex()),
                    'top': 0,
                    'left': 0,
                    'height': '100%',
                    'width': '100%',
                    'background': o.overlayColor,
                    'opacity': o.overlayOpacity,
                    'overflow': 'auto'
                }).appendTo(o.overlayParent);

                $modal.css({
                    'display': 'none',
                    'position': 'fixed',
                    // When updateZIndexOnOpen is set to true, we avoid computing the z-index on initialization,
                    // because the value would be replaced when opening the modal.
                    'z-index': (o.updateZIndexOnOpen ? 0 : o.zIndex() + 1)
                });
                $modal.find('h4').css({ 'text-align': 'center' });
                $modal.bind('openModal', function () {
                    var overlayZ = o.updateZIndexOnOpen ? o.zIndex() : parseInt($overlay.css('z-index'), 10),
                        modalZ = overlayZ + 1;

                    if (o.transitionIn !== '' && o.transitionOut !== '') {
                        $modal.removeClass(o.transitionOut).addClass(o.transitionIn);
                    }
                    $modal.css({
                        'display': 'block',
                        'z-index': modalZ
                    });

                    $overlay.css({ 'z-index': overlayZ, 'display': 'block' });

                    if (o.onOpen && typeof o.onOpen === 'function') {
                        // onOpen callback receives as argument the modal window
                        o.onOpen($modal[0]);
                    }
                });

                $modal.bind('closeModal', function () {
                    if (o.transitionIn !== '' && o.transitionOut !== '') {
                        $modal.removeClass(o.transitionIn).addClass(o.transitionOut);
                        $modal.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                            $modal.css('display', 'none');
                            $overlay.css('display', 'none');
                        });
                    }
                    else {
                        $modal.css('display', 'none');
                        $overlay.css('display', 'none');
                    }
                    if (o.onClose && typeof o.onClose === 'function') {
                        // onClose callback receives as argument the modal window
                        o.onClose($modal[0]);
                    }
                });

                // Close on overlay click
                $overlay.click(function () {
                    if (o.overlayClose) {
                        $modal.trigger('closeModal');
                    }
                });

                $(document).keydown(function (e) {
                    // ESCAPE key pressed
                    if (o.closeOnEscape && e.keyCode === 27) {
                        $modal.trigger('closeModal');
                    }
                });

                // Close when button pressed
                $modal.on('click', o.closeButtonClass, function (e) {
                    $modal.trigger('closeModal');
                    e.preventDefault();
                });

                // Automatically open modal if option set
                if (o.autoOpen) {
                    $modal.trigger('openModal');
                }

            });

        }
    };

    $.fn.easyModal = function (method) {

        // Method calling logic
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        }

        if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }

        $.error('Method ' + method + ' does not exist on jQuery.easyModal');

    };

} (jQuery));

function GetCommentSubmitted() {
    $('#iccShareForm').show();
    $('#iccShareDiv').hide();
    $('#preSubmit').hide();
    var dispScreenName = $.cookie("userComment");
    var dispHtml = $.cookie("userName");
    $("#objName").html(dispScreenName);
    $("#objComment").html(dispHtml);
    RemoveCookies();
};

function RemoveCookies() {
    $.removeCookie('userComment', { path: '/' });
    $.removeCookie('userName', { path: '/' });
    $.removeCookie('actualQstId', { path: '/' });
}

function CreateCookies(txtHtml, txtScreenName, questionId) {
	$.cookie('userComment', txtHtml, { path: "/" });
	$.cookie('userName', txtScreenName, { path: "/" });
	$.cookie('actualQstId', questionId, { path: "/" });
}

function ReadyToShare() {
    $('#iccShareForm').hide();
    $('#iccShareDiv').show();
    $('#preSubmit').show();
    $('#divConfirmation').hide();
    $('#submitComment').hide();
    $('#lblReadyToShare').show();
    $('#commentsInsideGroup').hide();
    $('#submitComment').show();
    $('#TopSliderArt').hide();
    $('#pBreadCrumbs').hide();
}

function ContainsHtmlTag(objTxt) {
    var iniComment = $('#txtComment').val();
    var txtElement = objTxt;
    var reg = /<(.|\n)*?>/g;

    if (reg.test($('#' + txtElement + '').val()) == true) {
        var htmlTagError = 'Your comment contains HTML \n - Remove HTML tags to continue';

        $('#' + txtElement + '').css({
            "color": "#D8000C",
            "border": "1px solid red",
            "background": "#FFBABA"
        });
        $('#' + txtElement + '').val(htmlTagError);
        setTimeout(function() {
            $('#' + txtElement + '').val(iniComment);
            $('#' + txtElement + '').css({
                "color": "",
                "border": "",
                "background": ""
            });
        }, 1200);

        return false;
    } else
        return true;
}

function IsDataValid() {

    if ($('#txtScreenName').val() == '') {
        SetError('txtScreenName', 'Provide Screen Name');
        return false;
    }
    if ($('#txtComment').val() == '' || $('#txtComment').val() == null) {
        SetError('txtComment', 'Provide comment');
        return false;
    }
    if ($('#chkAccept').prop('checked') == false) {
        $("#lblAcceptError").append("You must agree to the terms");
        $('#lblAcceptError').css({
            "color": "#D8000C",
            "border": "1px solid red",
            "background": "#FFBABA"
        });
        setTimeout(function() {
            $('#lblAcceptError').empty();
            $('#lblAcceptError').css({
                "border": "",
                "background": ""
            });
        }, 1200);
        return false;
    } else {
        return true;
    }
}

function SetError(objTxt, msg) {
    var txtElement = objTxt;

    $('#' + txtElement + '').css({
        "color": "#D8000C",
        "border": "1px solid red",
        "background": "#FFBABA"
    });
    $('#' + txtElement + '').val(msg);
    setTimeout(function() {
        $('#' + txtElement + '').val('');
        $('#' + txtElement + '').css({
            "color": "",
            "border": "",
            "background": ""
        });
    }, 1200);
    $('#' + txtElement + '').focus();
}

function ConfirmationShow() {
    $('#divShareHeader').hide();
    $('#divConfirmation').show();
    $('#lblReadyToShare').hide();
}

function ListShapes() {
    return ["black triangle", "white square", "black square", "white circle", "white triangle"];
}

function ArrayShuffle() {
    var array = ListShapes();
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

function DisplayCaptcha() {
    var arrShapes = ArrayShuffle();
    var dynQuestionShape;
    var shapeCookie;
    $.each(arrShapes, function(index, value) {
        $.cookie('captcha', index);
        shapeCookie = $.cookie('captcha');
        if (index == parseInt(shapeCookie)) {
            dynQuestionShape = value;
            $('#shapeOption').html(dynQuestionShape);
            $('#isValidOpt').val(value);
        }
    });
}

function IsConfirmation() {
    if ($.cookie('actualQstId') != null) {
        return true;
    } else {
        return false;
    }
}

function IsCaptchaValid() {
    if ($("input[type='radio'][name='shape']:checked").val() == $.inArray($('#isValidOpt').val(), ListShapes())) {
        return true;
    } else {
        SetError('lblshapeOptionMsg', '');
        return false;
    }
}

function GetGender() {
	var txtGender = $("input[type='radio'][name='gender']:checked").val() == null ? "0" : $("input[type='radio'][name='gender']:checked").val();
	return txtGender;
}

function GetRole() {
	var txtRole = $("input[type='radio'][name='role']:checked").val() == null ? "0" : $("input[type='radio'][name='role']:checked").val();
	return txtRole;
}

function SubmitComment(questionId, txtAgeRange,domainId,isMobile) {
    var txtGender =GetGender();
    var txtRole = GetRole();
    var txtScreenName = $('#txtScreenName').val();
    var txtHtml = $('#txtComment').val();
	var artIdOnSubmit = $.cookie('artIdOnSubmit');
		
	if(isMobile){
		$.removeCookie('artIdOnSubmit', { path: '/' });	
	}

	var data = { questId: questionId, Html: txtHtml, Name: txtScreenName, gender: txtGender, Role: txtRole, AgeRange: txtAgeRange,ArtIdOnSubmit: artIdOnSubmit};
	if ($('#success-comment').length) {
                $('#success-comment').trigger('openModal');
    	} else {
		$('<div id="success-comment" class="easy-modal"></div>').appendTo('body');
	        $('#success-comment').easyModal({
	            top: 200,
	            overlay: 0.2,
	            hasVariableWidth: true,
	            autoOpen: true,
	            message: 'Thank you for your submission. You will be redirected back to your article shortly.',
	        });
        }
    $.ajax({
        type: 'post',
        url: '/net/savecomment',
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function(d) {
	    setTimeout(function() {
                $('#success-comment').trigger('closeModal');
                window.location.href = d;
            }, 2000);
            
        },
        error: function(xhr, textStatus, errorThrown) {
            // alert("Please try again.");
            if ($('#fail-comment').length) {
                $('#fail-comment').trigger('openModal');
            } else {
                $('<div id="fail-comment" class="easy-modal"></div>').appendTo('body');
                $('#fail-comment .easy-modal-message').text('Please try again.');
                $('#fail-comment').easyModal({
                    top: 200,
                    overlay: 0.2,
                    hasVariableWidth: true,
                    autoOpen: true,
                    message: 'Please try again.'
                });
            }
        }
    });
}
//This function is triggered from MNI view that does not display Confirmation Page "Print for your records"
function SubmitCommentViewer(questionId, txtAgeRange) {
    var txtGender =GetGender();
    var txtRole = GetRole();
    var txtScreenName = $('#txtScreenName').val();
    var txtHtml = $('#txtComment').val();
    var data = { questId: questionId, Html: txtHtml, Name: txtScreenName, gender: txtGender, Role: txtRole, AgeRange: txtAgeRange };
    $.ajax({
        type: 'post',
        url: '/net/savecomment',
        data: JSON.stringify(data),
        contentType: "application/json",
        success: function (d) {
			window.location.href = "https://www.medicinenet.com/script/main/art.asp?articlekey=76639";
        },
        error: function (xhr, textStatus, errorThrown) {
            alert("Please try again.");
        }
    });
}